import React from 'react'
import { Row, Col } from 'antd'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Helmet } from '@/components'
import { Box, useMediaQuery } from '@mui/material'
import { Shibai2 } from '@imile/icons'

export default (props) => {
  const {} = useTranslation()
  const isPc = useMediaQuery('@media (min-width: 1460px)')

  return (
    <Box sx={{ background: '#F6F7FA' }}>
      <Helmet>
        <meta
          name='keywords'
          content='Careers at iMile, iMile jobs, Work for iMile, iMile delivery job, job opportunities iMile, Work at iMile'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/csp/channelServicePartner' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/1c763be06e4165a95c46d50a3039cf28/d5f9a/banner.webp'
        />
        <meta
          property='og:title'
          content='Unleash Your Potential: Partner with iMile for Global CSP Success'
        />
        <meta
          property='og:description'
          content='Calling all express delivery entrepreneurs and established businesses! Embrace the chance to connect with our international network through the iMile Global CSP Program.'
        />
      </Helmet>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100px',
          padding: '0 120px',
          background: 'linear-gradient(180deg, #181B63 0%, #151741 100%)',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: '120px',
            display: 'flex',
            zIndex: 2,
          }}
        >
          <Box
            component='img'
            src='/images/logos/logo_google_web.svg'
            sx={{ width: '80px', height: '40px', position: 'relative', top: '13px' }}
          />
          <Box sx={{ fontSize: '32px', color: '#fff', ml: '18px' }}>· Delete account</Box>
        </Box>
        <Box
          component='img'
          src='/images/logos/logo_google_bg_web.svg'
          sx={{
            width: '490px',
            height: '100px',
            position: 'absolute',
            left: '120px',
            zIndex: 1,
          }}
        />
      </Box>
      <Box sx={{ padding: '0 120px 16px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '30px 0 16px',
          }}
        >
          <Box sx={{ width: '60px', height: '1px', background: '#CECEDF' }}></Box>
          <Box
            sx={{
              fontSize: '18px',
              fontWeight: 'bold',
              margin: '0 30px',
            }}
          >
            Confirm Deletion
          </Box>
          <Box sx={{ width: '60px', height: '1px', background: '#CECEDF' }}></Box>
        </Box>
        <Box
          sx={{
            background: '#fff',
            padding: '24px 20px',
            borderRadius: '4px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              fontSize: '14px',
              lineHeight: '20px',
              background: 'rgba(255, 83, 0, 0.1)',
              color: '#FF5300',
              borderRadius: '4px',
              padding: '10px 12px',
            }}
          >
            <Shibai2 sx={{ mt: '2px' }} />
            <Box sx={{ marginLeft: '8px' }}>
              This account is a unified account of the iMile platform. After cancellation, your
              account information, identity information, and account balance will be cleared and
              cannot be recovered.
            </Box>
          </Box>
          <Box sx={{ m: '12px 0', fontSize: '14px', lineHeight: '24px', fontWeight: 'bold' }}>
            Thank you for using iMile. In order to protect your rights and interests, we need to
            review the following information before canceling your account
          </Box>
          <Box sx={{ fontSize: '14px', lineHeight: '24px', color: '#797E8F' }}>
            <div>
              1. You have cleared or you voluntarily gave up your iMile assets and virtual rights
            </div>
            <div>2. There are no outstanding orders and services in the account</div>
            <div>3. No ongoing complaints</div>
            <div>4. No unpaid bills</div>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '30px 0 16px',
          }}
        >
          <Box sx={{ width: '60px', height: '1px', background: '#CECEDF' }}></Box>
          <Box
            sx={{
              fontSize: '18px',
              fontWeight: 'bold',
              margin: '0 30px',
            }}
          >
            How to Delete account？
          </Box>
          <Box sx={{ width: '60px', height: '1px', background: '#CECEDF' }}></Box>
        </Box>
        <Box
          sx={{
            background: '#fff',
            borderRadius: '4px',
            padding: '20px 20px 30px',
          }}
        >
          <Box component='img' src='/images/logos/step_web_1.svg' />
          <Box
            sx={{
              mb: '4px',
              lineHeight: '24px',
              a: {
                textDecoration: 'underline',
              },
            }}
          >
            Log in to imile official website：
            <a onClick={() => window.open('https://express.imile.com/')}>
              https://express.imile.com/
            </a>
          </Box>
          <Box component='img' src='/images/logos/step_web_2.svg' sx={{ mt: '30px' }} />
          <div>
            Click to
            <span style={{ fontWeight: 'bold' }}>
              【Me】-【Account security】-【Delete account】
            </span>
          </div>
          <Box
            sx={{
              'img:nth-child(2n-1)': {
                position: 'relative',
                width: '360px',
                height: '209px',
                '&:hover': {
                  transform: 'scale(2)',
                  zIndex: 100,
                },
              },
            }}
          >
            <Box component='img' src='/images/logos/delete_account_1.png' sx={{ mt: '30px' }} />
            {isPc ? (
              <Box component='img' src='/images/logos/arrow_web.svg' sx={{ m: '0 10px' }} />
            ) : (
              <Box
                sx={{
                  img: {
                    width: '20px !important',
                    height: '20px !important',
                    ml: '160px',
                  },
                }}
              >
                <Box component='img' src='/images/logos/arrow_phone.svg' sx={{ m: '0 10px' }} />
              </Box>
            )}
            <Box component='img' src='/images/logos/delete_account_2.png' sx={{ mt: '30px' }} />
            {isPc ? (
              <Box component='img' src='/images/logos/arrow_web.svg' sx={{ m: '0 10px' }} />
            ) : (
              <Box
                sx={{
                  img: {
                    width: '20px !important',
                    height: '20px !important',
                    ml: '160px',
                  },
                }}
              >
                <Box component='img' src='/images/logos/arrow_phone.svg' sx={{ m: '0 10px' }} />
              </Box>
            )}
            <Box component='img' src='/images/logos/delete_account_3.png' sx={{ mt: '30px' }} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["csp"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
